<template>
  <div>
    <!-- BASIC INFO -->
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5>
          {{ $t("Informació bàsica") }}
        </h5>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Nom" label-for="firstName">
          <b-form-input id="firstName" v-model="client.firstName" trim />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Llinatges (opcional)" label-for="lastName">
          <b-form-input id="lastName" v-model="client.lastName" trim />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Email (opcional)" label-for="email">
          <b-form-input id="email" v-model="client.email" type="email" trim />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Telèfon (opcional)" label-for="phone">
          <b-form-input id="phone" v-model="client.phone" trim />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Tipus d'operació" label-for="operation">
          <b-form-radio-group
            id="operation"
            v-model="client.operation"
            :options="realEstateOperations"
            text-field="label"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Interessos (opcional)" label-for="interests">
          <v-select
            id="interests"
            v-model="client.interests"
            placeholder="Selecciona els interessos del client"
            :options="realEstateAccommodationTypeOptions"
            :reduce="(option) => option.value"
            :multiple="true"
            :close-on-select="false"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Poble (opcional)" label-for="cities">
          <v-select
            id="cities"
            v-model="client.cities"
            placeholder="Selecciona els pobles d'interès del client"
            :options="cityOptions"
            :reduce="(option) => option.value"
            :multiple="true"
            :close-on-select="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
} from "bootstrap-vue";
import {
  realEstateOperations,
  realEstateAccommodationTypeOptions,
} from "@/utils/select-options";
import vSelect from "vue-select";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    vSelect,
  },
  props: {
    value: {
      type: Object,
      defualt: null,
    },
  },
  data() {
    return {
      client: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        operation: null,
        interests: null,
        cities: null,
      },
      realEstateOperations,
      realEstateAccommodationTypeOptions,
    };
  },
  computed: {
    cities() {
      return this.$store.getters["places/cities"];
    },
    cityOptions() {
      return this.cities.map((city) => ({
        label: city.name,
        value: city.slug,
      }));
    },
  },
  watch: {
    client: {
      deep: true,
      handler(client) {
        this.$emit("input", client);
      },
    },
  },
  created() {
    if (!this.cities?.length) this.fetchPlaces();
  },
  mounted() {
    this.initClient();
  },
  methods: {
    initClient() {
      this.client.firstName = this.value?.firstName || null;
      this.client.lastName = this.value?.lastName || null;
      this.client.email = this.value?.email || null;
      this.client.phone = this.value?.phone || null;
      this.client.operation = this.value?.operation || "BUY";
      this.client.interests = this.value?.interests || [];
      this.client.cities = this.value?.cities || [];
    },
    fetchPlaces() {
      // TODO: only fetch place of type CITY
      this.$store
        .dispatch("places/fetchPlaces", { pagination: false })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPlaces.title"),
            this.$t("errors.fetchPlaces.description")
          )
        );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
